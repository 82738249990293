import React, { createContext, useEffect, useReducer } from 'react';
import axios from 'axios.js';
// import axios from 'axios';

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOAD_NOTIFICATIONS': {
      return {
        ...state,
        notifications: action.payload,
      };
    }
    case 'DELETE_NOTIFICATION': {
      return {
        ...state,
        notifications: action.payload,
      };
    }
    case 'CLEAR_NOTIFICATIONS': {
      return {
        ...state,
        notifications: action.payload,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const NotificationContext = createContext({
  notifications: [],
  deleteNotification: () => {},
  clearNotifications: () => {},
  getNotifications: () => {},
  createNotification: () => {},
});

export const NotificationProvider = ({ settings, children }) => {
  const [state, dispatch] = useReducer(reducer, []);
  const user=JSON.parse(localStorage.getItem('user'));

  console.log("state",state);


  const deleteNotification = async (userNotificationId) => {
    try {
      debugger;
      const res = await axios.delete('/api/notification/push', {
        params:{id: userNotificationId}
      });
      dispatch({
        type: 'DELETE_NOTIFICATION',
        payload: state.notifications.filter(notification=>notification.userNotificationId!==userNotificationId)||[],
      });
    } catch (e) {
      console.error(e);
    }
  };

  const clearNotifications = async () => {
    try {
      const res = await axios.delete('/api/notification/push',{
        params:{userId: user.id}

      });
      dispatch({
        type: 'CLEAR_NOTIFICATIONS',
        payload: [],
      });
    } catch (e) {
      console.error(e);
    }
  };

  const getNotifications = async () => {
    
    try {
      const res = await axios.get('/api/notification/push',{params:{userId:user.id}});
      console.log(res);
      dispatch({
        type: 'LOAD_NOTIFICATIONS',
        payload: res.data,
      });
    } catch (e) {
      console.error(e);
    }
  };
  const createNotification = async (notification) => {
    try {
      const res = await axios.post('/api/notification/add', {
        notification,
      });
      dispatch({
        type: 'CREATE_NOTIFICATION',
        payload: res.data,
      });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getNotifications();

    const intervalId = setInterval(() => {
      getNotifications();
    }, 10000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <NotificationContext.Provider
      value={{
        notifications: state.notifications,
        deleteNotification,
        clearNotifications,
        getNotifications,
        createNotification,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationContext;
