import React from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    Grid,
    Typography,
    Divider,
    styled
} from '@mui/material';
import { useThemeContext } from 'app/ThemeProvider';

const data = {
    'Connector ID': 1,
    'Transaction ID': 22037,
    'Timestamp': '2024-06-18T15:21:13Z',
    'MAC Address': '14:1F:BA:10:D1:6A'
};



const UtilizationTable = ({ chartData = {} }) => {

    const { darkTheme } = useThemeContext();

    const total =(parseInt(chartData.total_idle_time)
        + parseInt(chartData['total_runtime'])
        + parseInt(chartData['total_finishing_time'])
        + parseInt(chartData['total_preparing_time'])
        + parseInt(chartData['total_runtime_offline'])
        + parseInt(chartData['total_unavailable_time'])
        + parseInt(chartData['total_faulted_time']))??0;

    const uptime=(parseInt(chartData.total_idle_time)
    + parseInt(chartData['total_runtime'])
    + parseInt(chartData['total_finishing_time'])
    + parseInt(chartData['total_preparing_time'])
    + parseInt(chartData['total_runtime_offline']))*100/total;

    const downtime=(parseInt(chartData['total_unavailable_time'])
    + parseInt(chartData['total_faulted_time']))*100/total

    const Title1 = styled('span')(() => ({
        fontSize: '1rem',

        marginRight: '.5rem',
        textTransform: 'capitalize',
        fontWeight: "bold",
        fontSize: "18px",
        color: darkTheme ? '#F2E34C' : '#000',
    }));

    const Title2 = styled('span')(() => ({
        marginLeft: 5, fontSize: '16px', color: 'white', fontWeight: '500',
    }));


    const Papar2 = styled(Paper)(({ darkTheme }) => ({
        backgroundColor: darkTheme ? '#333333' : '#f9f9f9',
        height: '12vh',
        margin: '4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center'
    }));

    return (
        <Grid container >
            <Grid item md={6} style={{ textAlign: 'center', }}>
                <Grid padding={"10px"}>
                    <Title1 variant="h5" component="div" gutterBottom>
                        <b>Up Time  ({isNaN(uptime)?0:uptime.toFixed(2)} %)</b>
                    </Title1>
                </Grid>

                <Papar2 elevation={3} style={{ background: 'rgb(65, 105, 225)', color: 'white' }} >
                    <Grid container>
                        <Grid item width={"100%"} ><Title2 >Available (Mins)</Title2></Grid>
                        <Grid item width={"100%"}><Typography variant="h5" gutterBottom>
                            <b>{parseFloat(chartData.total_idle_time ?? 0).toFixed(2)}</b>
                        </Typography></Grid>
                    </Grid>
                </Papar2>

                <Papar2 elevation={3} style={{ background: 'rgb(218, 165, 32)', color: 'white' }} >

                    <Grid container >
                        <Grid item width={"100%"} ><Title2 >Pre-Processing (Mins)</Title2></Grid>
                        <Grid item width={"100%"}><Typography variant="h5" gutterBottom>
                            <b>{parseFloat(chartData.total_preparing_time ?? 0).toFixed(2)}</b>
                        </Typography></Grid>
                    </Grid>

                </Papar2>

                <Papar2 elevation={3} style={{ background: 'rgb(0, 128, 1)', color: 'white' }} >


                    <Grid container>
                        <Grid item width={"100%"} ><Title2 >Charging Online (Mins)</Title2></Grid>
                        <Grid item width={"100%"}><Typography variant="h5" gutterBottom>
                            <b>{parseFloat(chartData.total_runtime ?? 0).toFixed(2)}</b>
                        </Typography></Grid>
                    </Grid>

                </Papar2>

                <Papar2 elevation={3} style={{ background: 'rgb(0, 128, 1)', color: 'white' }}>

                    <Grid container>
                        <Grid item width={"100%"} ><Title2 >Charging Offline (Mins)</Title2></Grid>
                        <Grid item width={"100%"}><Typography variant="h5" gutterBottom>
                            <b>{parseFloat(chartData.total_runtime_offline ?? 0).toFixed(2)}</b>
                        </Typography></Grid>
                    </Grid>


                </Papar2>

                <Papar2 elevation={3} style={{ background: 'rgb(218, 165, 32)', color: 'white' }}>

                    <Grid container>
                        <Grid item width={"100%"} ><Title2 >Post-Processing (Mins)</Title2></Grid>
                        <Grid item width={"100%"}><Typography variant="h5" gutterBottom>
                            <b>{parseFloat(chartData.total_finishing_time ?? 0).toFixed(2)}</b>
                        </Typography></Grid>
                    </Grid>

                </Papar2>
            </Grid>


            <Grid item md={6} style={{ textAlign: 'center' }}>


                <Grid padding={"10px"} >
                    <Title1 variant="h5" component="div" gutterBottom>
                        <b>Down Time ({isNaN(downtime)?0:downtime.toFixed(2)} %)</b>
                    </Title1>
                </Grid>

                <Papar2 elevation={3} style={{ background: 'rgb(135, 142, 152)', color: 'white' }} >

                    <Grid container >
                        <Grid item width={"100%"} ><Title2 >Unavailable (Mins)</Title2></Grid>
                        <Grid item width={"100%"}><Typography variant="h5" gutterBottom>
                            <b>{parseFloat(chartData.total_unavailable_time ?? 0).toFixed(2)}</b>
                        </Typography></Grid>
                    </Grid>


                </Papar2>

                <Papar2 elevation={3} style={{ background: 'rgb(255, 86, 51)', color: 'white' }}>

                    <Grid container>
                        <Grid item width={"100%"} ><Title2 >Faulted (Mins)</Title2></Grid>
                        <Grid item width={"100%"}><Typography variant="h5" gutterBottom>
                            <b>{parseFloat(chartData.total_faulted_time ?? 0).toFixed(2)}</b>
                        </Typography></Grid>
                    </Grid>
                </Papar2>
            </Grid>
        </Grid>
    );
};

export default UtilizationTable;
