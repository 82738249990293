import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useLocation, useNavigate } from "react-router-dom";
import PersonAdd from '@mui/icons-material/PersonAdd';
import { useThemeContext } from 'app/ThemeProvider';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import SettingsIcon from '@mui/icons-material/Settings';
import EvStationIcon from '@mui/icons-material/EvStation';
export default function Setting() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const user = JSON.parse(localStorage.getItem("user"));

  const location = useLocation();
  const { darkTheme } = useThemeContext();


  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    document.getElementById('seticon').style.transform = 'rotate(-45deg)'
  };
  const navigate = useNavigate();
  function systemsetup() {
    navigate('/cmsmgmt/infra');
  }
  function operationalsetup() {
    navigate('/cmsmgmt/vehicletslot');
  }
  function useradmin() {
    navigate('/cmsmgmt/usrmgmt');
  }
  function rotateicon() {
    document.getElementById('seticon').style.transform = 'rotate(45deg)'
  }
  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>

        <Tooltip title="Settings">
          <IconButton
            onClick={handleClick}
            // style={{
            //   background:location.pathname==="/cmsmgmt/vehicletslot"||
            //   location.pathname==="/cmsmgmt/infra"
            //   ||
            //   location.pathname==="/cmsmgmt/usrmgmt"
            //   ?'inherit':'#fefefe'

            //   ,

            //   color: location.pathname==="/cmsmgmt/vehicletslot"||
            //   location.pathname==="/cmsmgmt/infra"
            //   ||
            //   location.pathname==="/cmsmgmt/usrmgmt" ? 'black':'grey'
            // }}
            size="small"
            // sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <SettingsIcon style={{ color: darkTheme ? '#f2e34c' : '#000' }} id='seticon' onClick={rotateicon} />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {
          user.role[0].includes('SUPERADMIN') ?
            <MenuItem onClick={systemsetup} style={{
              display: "flex", justifyContent: "left", padding: "5px", color: darkTheme ? '#fff' : '#000',
              backgroundColor: darkTheme ? '#000' : '#f9f9f9',
              borderColor: '#ff0000',
            }}>
              <img src="./../assets/images/menuicons/electricvehicle.svg" alt="electricvehicle" style={{ marginRight: '10px' }} />
              <span style={{ color: darkTheme ? '#f2e34c' : '#000' }}>System Setup</span>
            </MenuItem> : ""
        }



        <MenuItem onClick={operationalsetup} style={{
          display: "flex", justifyContent: "left", padding: "5px", color: darkTheme ? '#fff' : '#000',
          backgroundColor: darkTheme ? '#000' : '#f9f9f9',
          borderColor: '#ff0000',
        }}>
          <img src="./../assets/images/menuicons/operationalschedule.svg" alt="operationalschedule" style={{ marginRight: '10px' }} />
          <span style={{ color: darkTheme ? '#f2e34c' : '#000' }}>Operational Setup</span>
        </MenuItem>

        {
          user.role[0].includes('SUPERADMIN') ?
            <MenuItem onClick={useradmin} style={{
              display: "flex", justifyContent: "left", padding: "5px", color: darkTheme ? '#fff' : '#000',
              backgroundColor: darkTheme ? '#000' : '#f9f9f9',
              borderColor: '#ff0000',
            }}>
              <img src="./../assets/images/menuicons/useradminstration.svg" alt="useradminstration" style={{ marginRight: '10px' }} />
              <span style={{ color: darkTheme ? '#f2e34c' : '#000' }}>User Administration</span>
            </MenuItem> : ""
        }

        {/* {
          user.role[0].includes('ADMIN')?
          <MenuItem onClick={useradmin} style={{ display: "flex",justifyContent:"left", padding: "5px", backgroundColor: darkTheme ? '#000' : '#f9f9f9' }}>
          <img src="./../assets/images/menuicons/useradminstration.svg" alt="useradminstration" style={{ marginRight: '10px' }} />
          <span style={{ color: darkTheme ? '#f2e34c' : '#000' }}>User Administration</span>
        </MenuItem>:""
        } */}


        {
          user.role[0].includes('SUPERADMIN') ?
            <MenuItem onClick={() => navigate('/department/default')} style={{
              display: "flex", justifyContent: "left", padding: "5px", color: darkTheme ? '#fff' : '#000',
              backgroundColor: darkTheme ? '#000' : '#f9f9f9',
              borderColor: '#ff0000',
            }}>
              {/* <img src={<} alt="department" style={{ marginRight: '10px' }} /> */}
              <WorkspacesIcon style={{ marginRight: '10px' }} />
              <span style={{ color: darkTheme ? '#f2e34c' : '#000' }}>Department</span>
            </MenuItem> : ""
        }
        {
          user.role[0].includes('SUPERADMIN') ?
            <MenuItem onClick={() => navigate('/ev-fleet/default')} style={{
              display: "flex", justifyContent: "left", padding: "5px", color: darkTheme ? '#fff' : '#000',
              backgroundColor: darkTheme ? '#000' : '#f9f9f9',
              borderColor: '#ff0000',
            }}>
              {/* <img src={<} alt="department" style={{ marginRight: '10px' }} /> */}
              <DirectionsBusIcon style={{ marginRight: '10px' }} />
              <span style={{ color: darkTheme ? '#f2e34c' : '#000' }}>Fleet Manager</span>
            </MenuItem> : ""
        }

        {
          user.role[0].includes('SUPERADMIN') ?
            <MenuItem onClick={() => navigate('/slot/default')} style={{
              display: "flex", justifyContent: "left", padding: "5px", color: darkTheme ? '#fff' : '#000',
              backgroundColor: darkTheme ? '#000' : '#f9f9f9',
              borderColor: '#ff0000',
            }}>
              {/* <img src={<} alt="department" style={{ marginRight: '10px' }} /> */}
              <EvStationIcon style={{ marginRight: '10px' }} />
              <span style={{ color: darkTheme ? '#f2e34c' : '#000' }}>Open Charging</span>
            </MenuItem> : ""
        }



      </Menu>
    </React.Fragment>
  );
}