import React, { createContext, useEffect, useReducer } from 'react';
import jwtDecode from 'jwt-decode';
import axios from 'axios.js';
import { MatxLoading } from 'app/components';
import DepotSetupApi from 'app/views/setdepot/DepotSetupApi';
import UserApi from 'app/views/cmsmgmt/usrmgmt/UserApi';

const initialState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
};

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decodedToken = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  return decodedToken.exp > currentTime;
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);


    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.clear();
    delete axios.defaults.headers.common.Authorization;
    
  }
};
const fetchAssignedPermission=(depot,email)=>{
const api =new UserApi();

  api
      .getAssignedPermission({id:depot.id,username:email})
      .then((response) => {
          console.log(response);
          localStorage.setItem('permission', JSON.stringify(response.data));

      })
      .catch((e) => {
          // console.log('Cannot load event data', e);
      });
}
const setUser = (user) => {



  
  if (user) {

    const api=new DepotSetupApi();

    let depots=[];
    debugger;
    api
    .getPermittedDepotList2({username:user.email})
    .then((response) => {
      if(response.data.body.length>0){
        depots=response.data.body;
        if(depots.length>0){
          localStorage.setItem('selected_depot', depots[0].depotId);
          localStorage.setItem('selected_depotPermissionId',depots[0].id)
          axios.defaults.headers.depoId=depots[0].depotId;
          axios.defaults.headers.common.depoId=depots[0].depotId;
          fetchAssignedPermission(depots[0],user.email);
        }
        // console.log(depots)

      }
    })
    .catch((e) => {
      // console.log('Cannot load event data', e);
    });


   




    const userRole = user.role[0];
    localStorage.setItem('user_role', userRole);
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('orgId',user.orgId);
  } else {
    localStorage.removeItem('user');
    localStorage.removeItem('user_role');
    localStorage.removeItem('orgId');
    localStorage.removeItem('selected_depot');
          axios.defaults.headers.depoId=0;
          axios.defaults.headers.common.depoId=0;

    window.location.reload(true)

  }
  
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INIT': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };
    }
    case 'LOGIN': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
    case 'REGISTER': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => {},
  register: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const login = async (username, password) => {
    const response = await axios.post('/api/auth/sign_in', {
      username,
      password,
    });
    const { accessToken, user } = response.data;
    setSession(accessToken);



    //user.avatar = '/assets/images/face-6.jpg';
    setUser(user);
    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });

    localStorage.setItem('loginTimestamp',new Date());

  };

  const register = async (email, username, password) => {
    const response = await axios.post('/api/auth/register', {
      email,
      username,
      password,
    });

    const { accessToken, user } = response.data;

    setSession(accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  const logout = () => {
    setSession(null);
    setUser(null);
    dispatch({ type: 'LOGOUT' });
  };

  useEffect(() => {
    (async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          const userobj = JSON.parse(window.localStorage.getItem('user'));
          // const response = await axios.get('/api/auth/profile');
          const user = userobj;

          dispatch({
            type: 'INIT',
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: 'INIT',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INIT',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    })();
  }, []);

  if (!state.isInitialised) {
    return <MatxLoading />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
