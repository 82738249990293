import AuthGuard from 'app/auth/AuthGuard';
import dashboardRoutes from 'app/views/dashboard/DashboardRoutes';
import depotsetupRoutes from 'app/views/setdepot/DepotSetupRoutes';
import chargerProsRoutes from 'app/views/chargerprocess/ChargerProcessRoute';
import chargerRoutes from 'app/views/charger/ChargerRouter';
import reportRoutes from 'app/views/reports/ReportRoutes';
import profileRoutes from 'app/views/userprofile/UserProfileRoutes';
import managementRoutes from 'app/views/cmsmgmt/ManagementRoutes';
import NotFound from 'app/views/sessions/NotFound';
import sessionRoutes from 'app/views/sessions/SessionRoutes';
import simulationRoutes from 'app/views/simulation/SimulationRoutes';
import { Navigate } from 'react-router-dom';
import MatxLayout from './components/MatxLayout/MatxLayout';
import depoViewRoutes from './views/depoView/DepoViewRoutes';
import maps from './views/map/maps';
import analytics from './views/Analytics/analyt';
import departmentRoute from './views/cmsmgmt/department/DepartmentRoute';
import vehicleRoutes from './views/cmsmgmt/vehiclemngmnt2/VehicleRoute';
import evFleetRoutes from './views/evFleet/EvFleetRouter';
import slotRoutes from './views/open-charger/slot/SlotRoute';
import deviceMeterRoutes from './views/deviceMeter/DeviceMeterRoutes';

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...depotsetupRoutes,
      ...dashboardRoutes,
      ...depoViewRoutes,
      ...vehicleRoutes,
      ...chargerProsRoutes,
      ...chargerRoutes,
      ...managementRoutes,
      ...reportRoutes,
      ...profileRoutes,
      ...simulationRoutes,
      ...maps,
      ...departmentRoute,
      ...analytics,
      ...evFleetRoutes,
      ...slotRoutes,
      ...deviceMeterRoutes

    ],
  },
  ...sessionRoutes,
  { path: '/', element: <Navigate to="setdepot/default" /> },
  { path: '*', element: <NotFound /> },
];

export default routes;
