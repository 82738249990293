import React from 'react';
import { useThemeContext } from 'app/ThemeProvider';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import DarkModeIcon from '@mui/icons-material/DarkMode';

const ThemeSwitch = () => {
  const { darkTheme, toggleTheme } = useThemeContext();

  return (
    <div style={{ fontSize: '30px' }}>
      <input
        type="checkbox"
        id="darkThemeSwitch"
        checked={darkTheme}
        onChange={toggleTheme}
        style={{ display: 'none' }} // Hide the default checkbox
      />
      {/* Use ToggleOffIcon when darkTheme is off and DarkModeIcon when darkTheme is on */}
      {darkTheme ? <DarkModeIcon style={{ color:'#F2E34C' }} onClick={toggleTheme} /> : <WbSunnyIcon style={{ color:'#000' }} onClick={toggleTheme} />}
    </div>
  );
};

export default ThemeSwitch;
