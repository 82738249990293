// import React, { useEffect, useState } from 'react';
// import ReactECharts from 'echarts-for-react';
// import { Grid } from '@mui/material';
// import { chartOptions } from 'app/utils/utils';
// import { Paper, Typography } from '@mui/material';
// import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
// import MeterApi from './MeterApi';

// // import ChargerApi from '../../ChargerApi';

// const MeterPowerMonitor = ({ row = {}, setFrequency, setTotalConsumtion, setSelectedTransactionPk, darkTheme }) => {

//   console.log(row);
//   const api = new MeterApi();
//   const [data, setData] = useState({});
//   const [loading, setLoading] = useState(true);


//   useEffect(() => {
//     if (row)
//       fetchEnergyPowerMonitorByChargerAndMeterId();

//     const intervalId = setInterval(() => {
//       if (row)
//         fetchEnergyPowerMonitorByChargerAndMeterId();
//     }, 10000);

//     return () => {
//       clearInterval(intervalId);
//     };
//   }, [row]);

//   const fetchEnergyPowerMonitorByChargerAndMeterId = () => {
//     debugger;
//     api.getEnergyPowerMonitorByChargerAndMeterId(row)
//       .then(({ data }) => {
//         console.log(data);
//         if (data && data.length) {
//           setTotalConsumtion(data.map(obj => obj.consumption).reduce((v1, v2) => v1 + v2));
//         }
//         setFrequency(data.frequency_hz);
//         setData(data);
//         setLoading(false);
//       })
//       .catch((e) => {
//         console.log('Request history data', e);
//       });
//   };
//   const textColor = darkTheme ? '#F2E34C' : '#000'; // Conditionally set text color
//   const titleColor = darkTheme ? '#F2E34C' : '#000';
//   const tooltipColor = darkTheme ? '#333333' : '#ffffff';
//   const labelColor = darkTheme ? '#000' : '#6a7985';


//   return (
//     <Grid style={{ overflow: 'auto', height: "31.2rem" }}>
//       <TableContainer component={Paper}>
//         <Table>
//           <TableBody>
//             <TableRow>
//               <TableCell>
//                 <Typography variant="h6" style={{ fontWeight: 'bold', paddingLeft: '5px' }}>Energy</Typography>
//               </TableCell>
//               <TableCell>
//                 <Typography variant="h6" style={{ fontWeight: 'bold' }}>Voltage V</Typography>
//               </TableCell>
//               <TableCell></TableCell>
//               <TableCell></TableCell>
//             </TableRow>
//             <TableRow>
//               <TableCell style={{ fontWeight: 'bold', paddingLeft: '5px' }}>
//                 Active kWh - {data.active_total___import}
//               </TableCell>
//               <TableCell style={{ fontWeight: 'bold' }}>
//                 Average Voltage: {parseFloat(data.vavg__average_voltage__pls_refer_note1_below_).toFixed(2)}
//               </TableCell>
//               <TableCell></TableCell>
//               <TableCell></TableCell>
//             </TableRow>
//             <TableRow>
//               <TableCell style={{ padding: '5px' }}>
//                 Apparent kVAh: {data.apparent_when_active_import}
//               </TableCell>
//               <TableCell>
//                 V L1-L2: {parseFloat(data.v12__ry_voltage).toFixed(2)}
//               </TableCell>
//               <TableCell>L1:</TableCell>
//               <TableCell>
//                 L1 THD-V: {parseFloat(data.r_phase_voltage_thd).toFixed(2)}
//               </TableCell>
//             </TableRow>
//             <TableRow>
//               <TableCell style={{ padding: '5px' }}>
//                 Reactive kVArh: {parseFloat(data.reactive___import__q1_q2_).toFixed(2)}
//               </TableCell>
//               <TableCell>
//                 VL2-L3: {parseFloat(data.v23__yb_voltage).toFixed(2)}
//               </TableCell>
//               <TableCell>L2</TableCell>
//               <TableCell>
//                 L2 THD-V: {parseFloat(data.y_phase_voltage_thd).toFixed(2)}
//               </TableCell>
//             </TableRow>
//             <TableRow>
//               <TableCell></TableCell>
//               <TableCell>
//                 VL3-L1: {parseFloat(data.v31__br_voltage).toFixed(2)}
//               </TableCell>
//               <TableCell>L3:</TableCell>
//               <TableCell>
//                 L3 THD-V: {parseFloat(data.b_phase_voltage_thd).toFixed(2)}
//               </TableCell>
//             </TableRow>
//           </TableBody>
//         </Table>
//         <Table>
//           <TableBody>
//             <TableRow>
//               <TableCell>
//                 <Typography variant="h6" style={{ fontWeight: 'bold', paddingLeft: '5px' }}>Power</Typography>
//               </TableCell>
//               <TableCell>
//                 <Typography variant="h6" style={{ fontWeight: 'bold' }}>Current</Typography>
//               </TableCell>
//               <TableCell style={{ fontWeight: 'bold', width: 120 }}></TableCell>
//               <TableCell>
//                 <Typography variant="h6" style={{ fontWeight: 'bold', width: 210 }}>Power Factor</Typography>
//               </TableCell>
//             </TableRow>
//             <TableRow>
//               <TableCell style={{ fontWeight: 'bold', paddingLeft: '5px' }}>
//                 Active Power kW
//               </TableCell>
//               <TableCell style={{ fontWeight: 'bold' }}>
//                 Average Current: {parseFloat(data.average_current).toFixed(2)}
//               </TableCell>
//               <TableCell></TableCell>
//               <TableCell style={{ fontWeight: 'bold', width: 200 }}>
//                 Average power factor: {parseFloat(data.average_power_factor).toFixed(2)}
//               </TableCell>
//             </TableRow>
//             <TableRow>
//               <TableCell style={{ paddingLeft: '5px' }}>
//                 L1: {parseFloat(data.r__phase_active_power).toFixed(2)}
//               </TableCell>
//               <TableCell>
//                 L1: {parseFloat(data.l1__r_phase_line_current).toFixed(2)}
//               </TableCell>
//               <TableCell>
//                 L1 THD-I: {parseFloat(data.r_phase_current_thd).toFixed(2)}
//               </TableCell>
//               <TableCell>
//                 L1: {parseFloat(data.phase_1_power_factor).toFixed(2)}
//               </TableCell>
//             </TableRow>
//             <TableRow>
//               <TableCell style={{ paddingLeft: '5px' }}>
//                 L2: {parseFloat(data.y__phase_active_power).toFixed(2)}
//               </TableCell>
//               <TableCell>
//                 L2: {parseFloat(data.l2__y_phase_line_current).toFixed(2)}
//               </TableCell>
//               <TableCell>
//                 L2 THD-I: {parseFloat(data.y_phase_current_thd).toFixed(2)}
//               </TableCell>
//               <TableCell>
//                 L2: {parseFloat(data.phase_2_power_factor).toFixed(2)}
//               </TableCell>
//             </TableRow>
//             <TableRow>
//               <TableCell style={{ paddingLeft: '5px' }}>
//                 L3: {parseFloat(data.b__phase_active_power).toFixed(2)}
//               </TableCell>
//               <TableCell>
//                 L3: {parseFloat(data.l3__b_phase_line_current).toFixed(2)}
//               </TableCell>
//               <TableCell>
//                 L3 THD-I: {parseFloat(data.b_phase_current_thd).toFixed(2)}
//               </TableCell>
//               <TableCell>
//                 L3: {parseFloat(data.phase_3_power_factor).toFixed(2)}
//               </TableCell>
//             </TableRow>
//             <TableRow>
//               <TableCell style={{ fontWeight: 'bold', paddingLeft: '5px' }}>
//                 Apparent Power kVA
//               </TableCell>
//               <TableCell>
//                 LN: {parseFloat(data.ln__neutral_line_current).toFixed(2)}
//               </TableCell>
//               <TableCell></TableCell>
//               <TableCell style={{ fontWeight: 'bold' }}>
//                 Frequency Hz
//               </TableCell>
//             </TableRow>
//             <TableRow>
//               <TableCell style={{ paddingLeft: '5px' }}>
//                 L1: {parseFloat(data.r__phase_apparent_power).toFixed(2)}
//               </TableCell>
//               <TableCell>
//                 V-I (L1) Angle: {parseFloat(data.current_to_voltage_angle_r_phase).toFixed(2)}
//               </TableCell>
//               <TableCell></TableCell>
//               <TableCell>
//                 F: {parseFloat(data.frequency_hz).toFixed(2)}
//               </TableCell>
//             </TableRow>
//             <TableRow>
//               <TableCell style={{ paddingLeft: '5px' }}>
//                 L2: {parseFloat(data.y__phase_apparent_power).toFixed(2)}
//               </TableCell>
//               <TableCell>
//                 V-I (L2) Angle: {parseFloat(data.current_to_voltage_angle_y_phase).toFixed(2)}
//               </TableCell>
//               <TableCell></TableCell>
//               <TableCell></TableCell>
//             </TableRow>
//             <TableRow>
//               <TableCell style={{ paddingLeft: '5px' }}>
//                 L3: {parseFloat(data.b__phase_apparent_power).toFixed(2)}
//               </TableCell>
//               <TableCell>
//                 V-I (L3) Angle: {parseFloat(data.current_to_voltage_angle_b_phase).toFixed(2)}
//               </TableCell>
//               <TableCell></TableCell>
//               <TableCell></TableCell>
//             </TableRow>
//             <TableRow>
//               <TableCell style={{ fontWeight: 'bold', paddingLeft: '5px' }}>
//                 Reactive Power kVA
//               </TableCell>
//               <TableCell></TableCell>
//               <TableCell></TableCell>
//               <TableCell></TableCell>
//             </TableRow>
//             <TableRow>
//               <TableCell style={{ paddingLeft: '5px' }}>
//                 L1: {parseFloat(data.r__phase_reactive_power).toFixed(2)}
//               </TableCell>
//               <TableCell></TableCell>
//               <TableCell></TableCell>
//               <TableCell></TableCell>
//             </TableRow>
//             <TableRow>
//               <TableCell style={{ paddingLeft: '5px' }}>
//                 L2: {parseFloat(data.y__phase_reactive_power).toFixed(2)}
//               </TableCell>
//               <TableCell></TableCell>
//               <TableCell></TableCell>
//               <TableCell></TableCell>
//             </TableRow>
//             <TableRow>
//               <TableCell style={{ paddingLeft: '5px' }}>
//                 L3: {parseFloat(data.b__phase_reactive_power).toFixed(2)}
//               </TableCell>
//               <TableCell></TableCell>
//               <TableCell></TableCell>
//               <TableCell></TableCell>
//             </TableRow>
//           </TableBody>
//         </Table>
//       </TableContainer>
//     </Grid>

//   );
// };

// export default MeterPowerMonitor;


import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { Grid, Stack } from '@mui/material';
import { chartOptions } from 'app/utils/utils';
import { Paper, Typography } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import MeterApi from './MeterApi';
import { Height, Title } from '@mui/icons-material';

// import ChargerApi from '../../ChargerApi';

const MeterPowerMonitor = ({ row = {}, setFrequency, setPowerFactor,
  setTotalConsumtion, setSelectedTransactionPk, darkTheme }) => {

  console.log(row);
  const api = new MeterApi();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    if (Object.entries(row).length > 0)
      fetchEnergyPowerMonitorByChargerAndMeterId();

    const intervalId = setInterval(() => {
      if (Object.entries(row).length > 0)
        fetchEnergyPowerMonitorByChargerAndMeterId();
    }, 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, [Object.entries(row).length]);

  const fetchEnergyPowerMonitorByChargerAndMeterId = () => {
    debugger;
    api.getEnergyPowerMonitorByChargerAndMeterId(row)
      .then(({ data }) => {
        console.log(data);
        if (data && data.length) {
          setTotalConsumtion(data.map(obj => obj.consumption).reduce((v1, v2) => v1 + v2));
        }
        setFrequency(data.frequency_hz);
        setPowerFactor(data.average_power_factor);
        setData(data);
        setLoading(false);
      })
      .catch((e) => {
        console.log('Request history data', e);
      });
  };
  const textColor = darkTheme ? '#F2E34C' : '#000'; // Conditionally set text color
  const titleColor = darkTheme ? '#F2E34C' : '#000';
  const tooltipColor = darkTheme ? '#333333' : '#ffffff';
  const labelColor = darkTheme ? '#000' : '#6a7985';


  return (
    <Grid container spacing={1} style={{ overflow: 'auto', height: "31.2rem", backgroundColor: tooltipColor }}>
      <Grid item xs={4} style={{ border: '2px dashed grey', borderRadius: 15, height: '10rem', margin: 1 }}>
        <Typography style={{ fontSize: 18, fontWeight: 'bold', textAlign: 'left', color: textColor }}>
          Energy
        </Typography>
        <Grid >

          <Stack direction={'row'} margin={1}>
            <Typography style={{ fontWeight: 'bold', color: textColor }}>
              Active (kWh) :
            </Typography>
            <Typography style={{ color: darkTheme ? '#ffffff' : '#000000' }}>
              {parseFloat((data.active_total___import ?? 0)/1000).toFixed(2)}
            </Typography>
          </Stack>
          <Stack direction={'row'} margin={1}>
            <Typography style={{ fontWeight: 'bold', color: textColor }}>
              Apparent (kVAh) :
            </Typography>
            <Typography style={{ color: darkTheme ? '#ffffff' : '#000000' }}>
              {parseFloat((data.apparent_when_active_import ?? 0)/1000).toFixed(2)}
            </Typography>
          </Stack>
          <Stack direction={'row'} margin={1}>
            <Typography style={{ fontWeight: 'bold', color: textColor }}>
              Reactive (kVARh) :
            </Typography>
            <Typography style={{ color: darkTheme ? '#ffffff' : '#000000' }}>
              {parseFloat((data.reactive___import__q1_q2_ ?? 0)/1000).toFixed(2)}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Grid item xs={7.8} style={{ border: '2px dashed grey', borderRadius: 15, height: '10rem', margin: 1, marginLeft: 3 }}>
        <Typography style={{ fontSize: 18, fontWeight: 'bold', textAlign: 'left', color: textColor }}>
          Voltage
        </Typography>
        <Grid >
          <Stack direction={'row'} >
            <Typography style={{ fontWeight: 'bold', color: textColor }}>
              Average Voltage :
            </Typography>
            <Typography style={{ color: darkTheme ? '#ffffff' : '#000000' }}>
              {parseFloat(data.vavg__average_voltage ?? 0).toFixed(2)}
            </Typography>
          </Stack>
          <Grid container>
            <Grid item xs={4}>
              <Stack direction={'row'} margin={1}>
                <Typography style={{ fontWeight: 'bold', color: textColor }}>
                  V L1-L2 :
                </Typography>
                <Typography style={{ color: darkTheme ? '#ffffff' : '#000000' }}>
                  {parseFloat(data.v12__ry_voltage ?? 0).toFixed(2)}
                </Typography>
              </Stack>
              <Stack direction={'row'} margin={1}>
                <Typography style={{ fontWeight: 'bold', color: textColor }}>
                  V L2-L3 :
                </Typography>
                <Typography style={{ color: darkTheme ? '#ffffff' : '#000000' }}>
                  {parseFloat(data.v23__yb_voltage ?? 0).toFixed(2)}
                </Typography>
              </Stack>
              <Stack direction={'row'} margin={1}>
                <Typography style={{ fontWeight: 'bold', color: textColor }}>
                  V L3-L1 :
                </Typography>
                <Typography style={{ color: darkTheme ? '#ffffff' : '#000000' }}>
                  {parseFloat(data.v31__br_voltage ?? 0).toFixed(2)}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack direction={'row'} margin={1}>
                <Typography style={{ fontWeight: 'bold', color: textColor }}>
                  L1 :
                </Typography>
                <Typography style={{ color: darkTheme ? '#ffffff' : '#000000' }}>
                  {parseFloat(data.v1__r_phase_to_neutral_voltage ?? 0).toFixed(2)}

                </Typography>
              </Stack>
              <Stack direction={'row'} margin={1}>
                <Typography style={{ fontWeight: 'bold', color: textColor }}>
                  L2 :
                </Typography>
                <Typography style={{ color: darkTheme ? '#ffffff' : '#000000' }}>
                  {parseFloat(data.v2__y_phase_to_neutral_voltage ?? 0).toFixed(2)}

                </Typography>
              </Stack>
              <Stack direction={'row'} margin={1}>
                <Typography style={{ fontWeight: 'bold', color: textColor }}>
                  L3 :
                </Typography>
                <Typography style={{ color: darkTheme ? '#ffffff' : '#000000' }}>
                  {parseFloat(data.v3__b_phase_to_neutral_voltage ?? 0).toFixed(2)}

                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack direction={'row'} margin={1}>
                <Typography style={{ fontWeight: 'bold', color: textColor }}>
                  L1 THD-V :
                </Typography>
                <Typography style={{ color: darkTheme ? '#ffffff' : '#000000' }}>
                  {parseFloat(data.r_phase_voltage_thd ?? 0).toFixed(2)}
                </Typography>
              </Stack>
              <Stack direction={'row'} margin={1}>
                <Typography style={{ fontWeight: 'bold', color: textColor }}>
                  L2 THD-V :
                </Typography>
                <Typography style={{ color: darkTheme ? '#ffffff' : '#000000' }}>
                  {parseFloat(data.y_phase_voltage_thd ?? 0).toFixed(2)}
                </Typography>
              </Stack>
              <Stack direction={'row'} margin={1}>
                <Typography style={{ fontWeight: 'bold', color: textColor }}>
                  L3 THD-V :
                </Typography>
                <Typography style={{ color: darkTheme ? '#ffffff' : '#000000' }}>
                  {parseFloat(data.b_phase_voltage_thd ?? 0).toFixed(2)}
                </Typography>
              </Stack>
            </Grid>

          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={3.5} style={{ border: '2px dashed grey', borderRadius: 15, height: '27rem', margin: 1 }}>
        <Typography style={{ fontSize: 18, fontWeight: 'bold', textAlign: 'left', color: textColor }}>
          Power
        </Typography>
        <Grid >

          <Stack direction={'row'} margin={1}>
            <Typography style={{ fontWeight: 'bold', color: textColor }}>
              Active Power (kW)
            </Typography>
            <Typography style={{ color: darkTheme ? '#ffffff' : '#000000' }}>
              {parseFloat((data['3_phase_active_power'] ?? 0)/1000).toFixed(2)}
            </Typography>
          </Stack>
          <Stack direction={'row'} margin={1}>
            <Typography style={{ fontWeight: 'bold', color: textColor }}>
              L1 :
            </Typography>
            <Typography style={{ color: darkTheme ? '#ffffff' : '#000000' }}>
              {parseFloat(data.r__phase_active_power ?? 0/1000).toFixed(2)}
            </Typography>
          </Stack>
          <Stack direction={'row'} margin={1}>
            <Typography style={{ fontWeight: 'bold', color: textColor }}>
              L2 :
            </Typography>
            <Typography style={{ color: darkTheme ? '#ffffff' : '#000000' }}>
              {parseFloat(data.y__phase_reactive_power ?? 0/1000).toFixed(2)}
            </Typography>
          </Stack>
          <Stack direction={'row'} margin={1}>
            <Typography style={{ fontWeight: 'bold', color: textColor }}>
              L3 :
            </Typography>
            <Typography style={{ color: darkTheme ? '#ffffff' : '#000000' }}>
              {parseFloat((data.b__phase_active_power ?? 0)/1000).toFixed(2)}
            </Typography>
          </Stack>

          <Stack direction={'row'} margin={1}>
            <Typography style={{ fontWeight: 'bold', color: textColor }}>
              Apparent Power (kVA)
            </Typography>
            <Typography style={{ color: darkTheme ? '#ffffff' : '#000000' }}>
              {parseFloat((data['3_phase_apparent__power'] ?? 0)/1000).toFixed(2)}

            </Typography>
          </Stack>
          <Stack direction={'row'} margin={1}>
            <Typography style={{ fontWeight: 'bold', color: textColor }}>
              L1 :
            </Typography>
            <Typography style={{ color: darkTheme ? '#ffffff' : '#000000' }}>
              {parseFloat((data.r__phase_apparent_power ?? 0)/1000).toFixed(2)}
            </Typography>
          </Stack>
          <Stack direction={'row'} margin={1}>
            <Typography style={{ fontWeight: 'bold', color: textColor }}>
              L2 :
            </Typography>
            <Typography style={{ color: darkTheme ? '#ffffff' : '#000000' }}>
              {parseFloat((data.y__phase_apparent_power ?? 0)/1000).toFixed(2)}
            </Typography>
          </Stack>
          <Stack direction={'row'} margin={1}>
            <Typography style={{ fontWeight: 'bold', color: textColor }}>
              L3 :
            </Typography>
            <Typography style={{ color: darkTheme ? '#ffffff' : '#000000' }}>
              {parseFloat((data.b__phase_apparent_power ?? 0)/1000).toFixed(2)}
            </Typography>
          </Stack>

          <Stack direction={'row'} margin={1}>
            <Typography style={{ fontWeight: 'bold', color: textColor }}>
              Reactive Power (kVAr)
            </Typography>
            <Typography style={{ color: darkTheme ? '#ffffff' : '#000000' }}>
              {parseFloat((data['3_phase_reactive_power'] ?? 0)/1000).toFixed(2)}
            </Typography>
          </Stack>
          <Stack direction={'row'} margin={1}>
            <Typography style={{ fontWeight: 'bold', color: textColor }}>
              L1 :
            </Typography>
            <Typography style={{ color: darkTheme ? '#ffffff' : '#000000' }}>
              {parseFloat((data.r__phase_reactive_power ?? 0)/1000).toFixed(2)}
            </Typography>
          </Stack>
          <Stack direction={'row'} margin={1}>
            <Typography style={{ fontWeight: 'bold', color: textColor }}>
              L2 :
            </Typography>
            <Typography style={{ color: darkTheme ? '#ffffff' : '#000000' }}>
              {parseFloat((data.y__phase_reactive_power ?? 0)/1000).toFixed(2)}
            </Typography>
          </Stack>
          <Stack direction={'row'} margin={1}>
            <Typography style={{ fontWeight: 'bold', color: textColor }}>
              L3 :
            </Typography>
            <Typography style={{ color: darkTheme ? '#ffffff' : '#000000' }}>
              {parseFloat((data.b__phase_reactive_power ?? 0)/1000).toFixed(2)}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Grid item xs={8.4} >
        <Grid xs={12} style={{ border: '2px dashed grey', borderRadius: 15, marginLeft: -5, marginTop: -5, padding: 7 }}>
          <Typography style={{ fontSize: 18, fontWeight: 'bold', textAlign: 'left', color: textColor }}>
            Current
          </Typography>
          <Grid >
            <Grid container>

              <Grid item xs={4}>
                <Stack direction={'row'} margin={1}>
                  <Typography style={{ fontWeight: 'bold', color: textColor }}>
                    Average Current :
                  </Typography>
                  <Typography style={{ color: darkTheme ? '#ffffff' : '#000000' }}>
                    {parseFloat(data.average_current ?? 0).toFixed(2)}
                  </Typography>
                </Stack>
                <Stack direction={'row'} margin={1}>
                  <Typography style={{ fontWeight: 'bold', color: textColor }}>
                    L1 :
                  </Typography>
                  <Typography style={{ color: darkTheme ? '#ffffff' : '#000000' }}>
                    {parseFloat(data.l1__r_phase_line_current ?? 0).toFixed(2)}
                  </Typography>
                </Stack>
                <Stack direction={'row'} margin={1}>
                  <Typography style={{ fontWeight: 'bold', color: textColor }}>
                    L2 :
                  </Typography>
                  <Typography style={{ color: darkTheme ? '#ffffff' : '#000000' }}>
                    {parseFloat(data.l2__y_phase_line_current ?? 0).toFixed(2)}
                  </Typography>
                </Stack>
                <Stack direction={'row'} margin={1}>
                  <Typography style={{ fontWeight: 'bold', color: textColor }}>
                    L3 :
                  </Typography>
                  <Typography style={{ color: darkTheme ? '#ffffff' : '#000000' }}>
                    {parseFloat(data.l3__b_phase_line_current ?? 0).toFixed(2)}
                  </Typography>
                </Stack>
                <Stack direction={'row'} margin={1}>
                  <Typography style={{ fontWeight: 'bold', color: textColor }}>
                    LN :
                  </Typography>
                  <Typography style={{ color: darkTheme ? '#ffffff' : '#000000' }}>
                    {parseFloat(data.ln__neutral_line_current ?? 0).toFixed(2)}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <div style={{ height: 27 }}></div>
                <Stack direction={'row'} margin={1}>
                  <Typography style={{ fontWeight: 'bold', color: textColor }}>
                    V-I (L1) Angle :
                  </Typography>
                  <Typography style={{ color: darkTheme ? '#ffffff' : '#000000' }}>
                    {parseFloat(data.current_to_voltage_angle_r_phase ?? 0).toFixed(2)}
                  </Typography>
                </Stack>
                <Stack direction={'row'} margin={1}>
                  <Typography style={{ fontWeight: 'bold', color: textColor }}>
                    V-I (L2) Angle :
                  </Typography>
                  <Typography style={{ color: darkTheme ? '#ffffff' : '#000000' }}>
                    {parseFloat(data.current_to_voltage_angle_y_phase ?? 0).toFixed(2)}
                  </Typography>
                </Stack>
                <Stack direction={'row'} margin={1}>
                  <Typography style={{ fontWeight: 'bold', color: textColor }}>
                    V-I (L3) Angle :
                  </Typography>
                  <Typography style={{ color: darkTheme ? '#ffffff' : '#000000' }}>
                    {parseFloat(data.current_to_voltage_angle_b_phase ?? 0).toFixed(2)}
                  </Typography >
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack direction={'row'} margin={1} height={20}>

                </Stack>
                <Stack direction={'row'} margin={1}>
                  <Typography style={{ fontWeight: 'bold', color: textColor }}>
                    L1 THD-I :
                  </Typography>
                  <Typography style={{ color: darkTheme ? '#ffffff' : '#000000' }}>
                    {parseFloat(data.r_phase_current_thd ?? 0).toFixed(2)}
                  </Typography>
                </Stack>
                <Stack direction={'row'} margin={1}>
                  <Typography style={{ fontWeight: 'bold', color: textColor }}>
                    L2 THD-I :
                  </Typography>
                  <Typography style={{ color: darkTheme ? '#ffffff' : '#000000' }}>
                    {parseFloat(data.y_phase_current_thd ?? 0).toFixed(2)}
                  </Typography>
                </Stack>
                <Stack direction={'row'} margin={1}>
                  <Typography style={{ fontWeight: 'bold', color: textColor }}>
                    L3 THD-I :
                  </Typography>
                  <Typography style={{ color: darkTheme ? '#ffffff' : '#000000' }}>
                    {parseFloat(data.b_phase_current_thd ?? 0).toFixed(2)}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container xs={12}>
          <Grid item xs={6} style={{ height: '27rem', marginLeft: -4, marginTop: 10 }}>

            <Grid height={'14rem'} style={{ border: '2px dashed grey', marginBottom: 5, marginTop: -7, padding: 10, borderRadius: 15 }}>
              <Typography style={{ fontSize: 18, fontWeight: 'bold', textAlign: 'left', color: textColor }}>
                Power Factor
              </Typography>
              <Stack direction={'row'} margin={1}>
                <Typography style={{ fontWeight: 'bold', color: textColor }}>
                  Average Power factor :
                </Typography>
                <Typography style={{ color: darkTheme ? '#ffffff' : '#000000' }}>
                  {parseFloat(data.average_power_factor ?? 0).toFixed(2)}
                </Typography>
              </Stack>

              <Stack direction={'row'} margin={1}>
                <Typography style={{ fontWeight: 'bold', color: textColor }}>
                  L1 :
                </Typography>
                <Typography style={{ color: darkTheme ? '#ffffff' : '#000000' }}>
                  {parseFloat(data.phase_1_power_factor ?? 0).toFixed(2)}
                </Typography>
              </Stack>
              <Stack direction={'row'} margin={1}>
                <Typography style={{ fontWeight: 'bold', color: textColor }}>
                  L2 :
                </Typography>
                <Typography style={{ color: darkTheme ? '#ffffff' : '#000000' }}>
                  {parseFloat(data.phase_2_power_factor ?? 0).toFixed(2)}
                </Typography>
              </Stack>
              <Stack direction={'row'} margin={1}>
                <Typography style={{ fontWeight: 'bold', color: textColor }}>
                  L3 :
                </Typography>
                <Typography style={{ color: darkTheme ? '#ffffff' : '#000000' }}>
                  {parseFloat(data.phase_3_power_factor ?? 0).toFixed(2)}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid item xs={5.8} style={{ height: '14rem', border: '2px dashed grey', borderRadius: 15, padding: 10, borderRadius: 15, marginLeft: 4, marginTop: 4 }}>
            <Stack>

              <Typography style={{ fontSize: 18, fontWeight: 'bold', textAlign: 'left', color: textColor }}>
                Frequency Hz :
              </Typography>

            </Stack>
            <Stack direction={'row'} margin={1}>
              <Typography style={{ fontWeight: 'bold', color: textColor }}>
                F :
              </Typography>
              <Typography style={{ color: darkTheme ? '#ffffff' : '#000000' }}>
                {parseFloat(data.frequency_hz ?? 0).toFixed(2)}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Grid>

    </Grid>

  );
};

export default MeterPowerMonitor;

