import axios from 'axios.js';

export default class DashboardApi {
  constructor() {
    this.api_token = null;
    this.client = null;
    this.user = JSON.parse(localStorage.getItem('user'));
  }

  init = () => {
    this.client = axios.create({
      timeout: 320000,
    });

    return this.client;
  };

  getChargerStatus = (params) => {
    return this.init().get('/api/dash/charger_status', { params: params });
  };

  getFleetStatus = (params) => {
    return this.init().get('/api/dash/fleet_status', { params: params });
  };

  getChargerEvents = (params) => {
   return this.init().get('/api/dash/charger_events', { params: params });
    //return this.init().post('/users', data);
  };

  getEnergyUses = (params) => {
    return this.init().get('/api/dash/charge_energy_uses', { params: params });
  };

  getLoadProfile = (params) => {
    return this.init().get('/api/dash/charger_load_profile', { params: params });
  };
  getEnergyMeter = (params) => {
    return this.init().get('/api/dash/charger_energy_meter', { params: params });
  };

  getChargerBayList = (params, depotId) => {
    return this.init().get('/api/charger/show_chargerbay/' + depotId, { params: params });
  };

  getCriticalChargingList = (params) => {
    return this.init().get('/api/dash/critical_charging/', { params: params });
  };

  getChargeBayChargerGraph = (params, chargeBayId) => {
    return this.init().get('/api/dash/chargebay_charger/' + chargeBayId, { params: params });
  };

  getVehicleArrivalSchedule = (params) => {
    return this.init().get('/api/vehicle/arrival_status/', { params: params });
  };

  getVehicleDepartureSchedule = (params) => {
    return this.init().get('/api/vehicle/departure_status/', { params: params });
  };

  getPowerMeter = (params) => {
    return this.init().get('/api/dash/power_meter', { params: params });
  };

  getChargerBayLoad=()=>{
    return this.init().get('/api/charger/current-chargebay-load', {});
  }
  
  getWeeklyChargeCount=()=>{
    return this.init().get('/api/dash/total_vehicle_charged_week', {});
  }
  getWeeklyChargingTimeInSec=()=>{
    return this.init().get('/api/dash/avg_charging_time_week', {});

  }
  getWeeklyEnergyUsed=()=>{
    return this.init().get('/api/dash/avg_energy_used_week', {});

  }
  getChargerLoadProfileStacked=()=>{
    return this.init().get('/api/dash/charger_load_profile_stacked', {});

  }

  getChargerLoadProfileStackedTest=()=>{
    return this.init().get('/api/dash/test_charger_load_profile_stacked', {});

  }

  getDepotLoadProfileStacked = (data) => {
    return this.init().post('/api/dash/load_profile_filter', data);
  };

  
  getDepotLoadProfileStackedAvg = (data) => {
    return this.init().post('/api/dash/avg_load_profile_filter', data);
  };

  getWeeklyEnergyConsumption=()=>{
    return this.init().get('/api/dash/energy_consumption', {});

  }
  getWeeklyCost=()=>{
    return this.init().get('/api/dash/avg_charging_cost', {});

  }

  getWeeklyEmission=()=>{
    return this.init().get('/api/dash/weekly_carbon_emission', {});

  }
  

// Analystics Report

getWeeklyChargeCountFilter=(filtertype)=>{
  return this.init().get('/api/analytics_report/vehicle_charged_week/'+filtertype, {});
}

  getWeeklyChargingTimeInSecFilter=(filtertype)=>{
    return this.init().get('/api/analytics_report/charging_time_week/'+filtertype, {});

  }
  getEnergyUsedFilter=(filtertype)=>{
    return this.init().get('/api/analytics_report/energy_used_week/'+filtertype, {});

  }

  getPowerWithChargerCount=(data)=>{
    return this.init().post('/api/dash/charger_load_profile_count', data);

  }
}
