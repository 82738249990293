import React, { useState, useEffect } from 'react';
import { Grid, Typography,styled,  Stack } from '@mui/material'
import { useThemeContext } from 'app/ThemeProvider';
import DashboardApi from 'app/views/dashboard/DashboardApi';

const Title = styled('span')(() => ({
    fontSize: '1rem',
    fontWeight: '500',
    marginRight: '.5rem',
    textTransform: 'capitalize',
  }));

const AnalyticsSummary = ({selectedFilter,timeFilterDto}) => {
    const [weeklyChargeCount, setWeeklyChargeCount] = useState(0);
    const [VehicleChargeStatus, setVehicleChargeStatus] = useState("up");
    const [vehicleChargePrcnt, setVehicleChargePrcnt] = useState(0.00);
    const [total_transaction, setTotal_transaction] = useState(0);
    const [total_transactionStatus, setTotal_transactionStatus] = useState("up");
    const [total_transactionStatusPrcnt, setTotal_transactionStatusPrcnt] = useState(0.00);
    
    const [weeklyChargingTimeInSec, setWeeklyChargingTimeInSec] = useState(0);
    const [avgWeeklyChargingTimeStatus, setAvgWeeklyChargingTimeStatus] = useState("up");
    const [weeklyChargingTimeInPrcnt, setWeeklyChargingTimeInPrcnt] = useState(0.00);
  
    const [weeklyEnergyUsed, setWeeklyEnergyUsed] = useState(0);
    const [avgWeeklyEnergyTimeStatus, setWeeklyEnergyTimeInSec] = useState("up");
    const [weeklyEnergyTimeInPrcnt, setWeeklyEnergyTimeInPrcnt] = useState(0.00);
  
    
    const { darkTheme } = useThemeContext();
    const textColor = darkTheme ? '#F2E34C' : '#000';
    
    const api = new DashboardApi();

   
    const [loading, setLoading] = useState(true);

    useEffect(() => {
       // analysticsDepotLoadProfile();
       fetchChargeCount();
       fetchChargingTimeInSec();
       fetchEnergyUsed();
    }, [selectedFilter,timeFilterDto]);

    const fetchChargeCount = () => {
        let isCanceled = false;
      
        api
          .getWeeklyChargeCountFilter(selectedFilter)
          .then(({ data }) => {
            if (!isCanceled) {
              setVehicleChargeStatus(data.vehicle_charged_status);
              setVehicleChargePrcnt(parseFloat(data.vehicle_charged_percent).toFixed(2));
              setWeeklyChargeCount(data.total_vehicle_charged);
    
              setTotal_transaction(data.total_transaction);
              setTotal_transactionStatus(data.transaction_status);
              setTotal_transactionStatusPrcnt(parseFloat(data.transaction_percent).toFixed(2));
    
            }
          })
          .catch((e) => {
            console.log('Request history data', e);
          });
      };
    
      const fetchChargingTimeInSec = () => {
        let isCanceled = false;
        debugger;
        api
          .getWeeklyChargingTimeInSecFilter(selectedFilter)
          .then(({ data }) => {
            if (!isCanceled) {
              setAvgWeeklyChargingTimeStatus(data.avg_charging_time_status);
              setWeeklyChargingTimeInPrcnt(parseFloat(data.avg_charging_time_percent).toFixed(2));
              setWeeklyChargingTimeInSec(data.avg_charging_time_seconds);
            }
          })
          .catch((e) => {
            console.log('Request history data', e);
          });
      };
    
      const fetchEnergyUsed = () => {
        let isCanceled = false;
        debugger;
        api
          .getEnergyUsedFilter(selectedFilter)
          .then(({ data }) => {
            if (!isCanceled) {
              setWeeklyEnergyUsed(data.avg_energy_used_week);
              setWeeklyEnergyTimeInPrcnt(parseFloat(data.avg_energy_used_last_percent).toFixed(2));
              setWeeklyEnergyTimeInSec(data.avg_energy_used_last_status);
            }
          })
          .catch((e) => {
            console.log('Request history data', e);
          });
      };

    return (
       <>
                <div style={{ display: 'flex'}}>
                  <div>
                    <Title style={{ fontWeight: "bold", fontSize: "18px", color: darkTheme ? '#F2E34C' : '#000' }}>Depot Statistics</Title>
                  </div>
                </div>
                <hr style={{ borderColor: darkTheme ? '#848482' : '#333333' }} /> 
                <Stack orientation='vertical' spacing={-1} sx={{ marginTop: '5px'}}>
                
                  <div>
                      <Title style={{ marginLeft: 5, fontSize: '16px', color: darkTheme ? '#fff' : '#000' }}>Vehicle Charged</Title>
                    </div>
                    <Grid container direction={'row'} >

                      <Grid item xs={12} direction={'column'} style={{ height: "7vh" }} >
                        <Typography style={{
                          fontSize: '25px',
                          fontWeight: 'bold',
                          paddingTop: '10px',
                          paddingLeft: "40px",
                          width: '100%', height: "100%",
                          color: darkTheme ? '#fff' : '#000'
                        }}>{weeklyChargeCount} 
                        
                         {/* <span
                        style={{
                          padding: "2px 10px 2px 10px", borderRadius: 10,
                          backgroundColor: 'green', color: 'white',
                          fontSize: '12px'
                        }}>
                            +1 </span> <span style={{
                          fontSize: '12px'
                        }} > &nbsp; Since Last Week </span> */}
                        
                        </Typography>
                      </Grid>

                      
                    </Grid>
                    <hr style={{ borderColor: darkTheme ? '#848482' : '#333333',marginTop:5 }} /> 
                  </Stack>
                  
                  <Stack orientation='vertical' spacing={-1} sx={{ marginTop: '5px'}}>
                    <div>
                      <Title style={{ marginLeft: 5, fontSize: '16px', color: darkTheme ? '#fff' : '#000' }}>Total Sessions</Title>
                    </div>
                    <Grid container direction={'row'} >

                      <Grid item xs={12} direction={'column'} style={{ height: "7vh" }} >
                        <Typography style={{
                          fontSize: '25px',
                          fontWeight: 'bold',
                          paddingTop: '10px',
                          paddingLeft: "40px",
                          width: '100%', height: "100%",
                          color: darkTheme ? '#fff' : '#000'
                        }}>{total_transaction} 
                         
                         {/* <span
                        style={{
                          padding: "2px 10px 2px 10px", borderRadius: 10,
                          backgroundColor: 'green', color: 'white',
                          fontSize: '12px'
                        }}>+1 </span> <span style={{
                          fontSize: '12px'
                        }} > &nbsp; Since Last Week </span> */}
                        
                        </Typography> 
                      </Grid>
                      
                    </Grid>
                    <hr style={{ borderColor: darkTheme ? '#848482' : '#333333',marginTop:5 }} /> 
                  </Stack>
                 
                  <Stack orientation='vertical' spacing={-1} sx={{ marginTop: '5px'}}>
                    <div>
                      <Title style={{ marginLeft: 5, fontSize: '16px', color: darkTheme ? '#fff' : '#000' }}>Avg. Charging Duration <a style={{fontSize:'10px'}}>(connector wise)</a></Title>
                    </div>
                    <Grid container direction={'row'} >

                      <Grid item xs={12} direction={'column'} style={{ height: "7vh" }} >
                        <Typography style={{
                          fontSize: '25px',
                          fontWeight: 'bold', paddingLeft: "40px",
                          paddingTop: '10px',
                          width: '100%', height: "100%",
                          color: darkTheme ? '#fff' : '#000'
                        }}>{Math.floor(weeklyChargingTimeInSec / 60)}<a style={{ fontSize: "12px" }}> Minutes</a>
                       &nbsp; 
                       
                       {/* <span
                        style={{
                          padding: "2px 10px 2px 10px", borderRadius: 10,
                          backgroundColor: 'green', color: 'white',
                          fontSize: '12px'
                        }}>+1 </span> <span style={{
                          fontSize: '12px'
                        }} > &nbsp; Since Last Week </span> */}


                        </Typography>
                      </Grid>
                      
                    </Grid>
                    <hr style={{ borderColor: darkTheme ? '#848482' : '#333333',marginTop:5 }} /> 
                  </Stack>
                  
                <Stack orientation='vertical' spacing={-1} sx={{ marginTop: '5px'}}>
                    <div>
                      <Title style={{ marginLeft: 5, fontSize: '16px', color: darkTheme ? '#fff' : '#000' }}>Avg. Energy Provided <a style={{fontSize:'10px'}}>(connector wise)</a></Title>
                    </div>
                    <Grid container direction={'row'} >

                      <Grid item xs={12} direction={'column'} style={{ height: "7vh" }} >
                        <Typography style={{
                          fontSize: '25px',
                          fontWeight: 'bold', paddingLeft: "40px",
                          paddingTop: '10px',
                          width: '100%', height: "100%",
                          color: darkTheme ? '#fff' : '#000'
                        }}>{Math.round(parseFloat(weeklyEnergyUsed))}<span style={{ fontSize: "12px" }}> kWh</span> 
                         &nbsp;
                          {/* <span
                        style={{
                          padding: "2px 10px 2px 10px", borderRadius: 10,
                          backgroundColor: 'green', color: 'white',
                          fontSize: '12px'
                        }}>+1 </span> <span style={{
                          fontSize: '12px'
                        }} > &nbsp; Since Last Week </span>  */}
                        
                        </Typography>
                      </Grid>
                      
                    </Grid>

                  </Stack>
       </>
    );
};
export default AnalyticsSummary;