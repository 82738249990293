import { Badge, Button, Card, Drawer, Grid, Icon, IconButton, ThemeProvider } from '@mui/material';
import { Box, styled, useTheme } from '@mui/system';
import useNotification from 'app/hooks/useNotification';
import useSettings from 'app/hooks/useSettings';
import { sideNavWidth, topBarHeight } from 'app/utils/constant';
import React, { Fragment } from 'react';
import { themeShadows } from '../MatxTheme/themeColors';
import { Paragraph } from '../Typography';
import { useThemeContext } from 'app/ThemeProvider';
import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@mui/icons-material/Info';

const useStyles = makeStyles((theme) => ({
  icon: {
    animation: '$spin 0.3s infinite linear', // Apply spin animation
  },
  '@keyframes spin': {
    from: {
      transform: 'rotate(-20deg)',
    },
    to: {
      transform: 'rotate(20deg)',
    },
  },
}));



const Notification = styled('div')(() => ({
  padding: '16px',
  marginBottom: '16px',
  display: 'flex',
  alignItems: 'center',
  height: topBarHeight,
  boxShadow: themeShadows[6],
  '& h5': {
    marginLeft: '8px',
    marginTop: 0,
    marginBottom: 0,
    fontWeight: '500',
  },
}));

const NotificationCard = styled(Box)(({ theme }) => ({
  position: 'relative',
  '&:hover': {
    '& .messageTime': {
      display: 'none',
    },
    '& .deleteButton': {
      opacity: '1',
    },
  },
  '& .messageTime': {
    color: theme.palette.text.secondary,
  },
  '& .icon': { fontSize: '1.25rem' },
}));

const DeleteButton = styled(IconButton)(({ theme }) => ({
  opacity: '0',
  position: 'absolute',
  right: 5,
  marginTop: 9,
  marginRight: '24px',
  background: 'rgba(0, 0, 0, 0.01)',
}));

const CardLeftContent = styled('div')(({ theme }) => ({
  padding: '12px 8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  background: 'rgba(0, 0, 0, 0.01)',
  '& small': {
    fontWeight: '500',
    marginLeft: '16px',
    color: theme.palette.text.secondary,
  },
}));

const Heading = styled('span')(({ theme }) => ({
  fontWeight: '500',
  marginLeft: '16px',
  color: theme.palette.text.secondary,
}));

const NotificationBar = ({ container }) => {
  const { settings } = useSettings();
  const classes = useStyles();
  const theme = useTheme();
  const secondary = theme.palette.text.secondary;
  const [panelOpen, setPanelOpen] = React.useState(false);
  const { deleteNotification, clearNotifications, notifications = [] } = useNotification();
  const { darkTheme } = useThemeContext();
  const handleDrawerToggle = () => {
    setPanelOpen(!panelOpen);
  };

  const { palette } = useTheme();
  const textColor = palette.text.primary;

  const getStatusColor = (status) => {
    if (status === "Available")
      return "rgb(65, 105, 225)"
    else if (status === "Unavailable")
      return "rgb(135, 142, 152)"
    else if (status === "Faulted")
      return "rgb(255, 86, 51)"
    else if (status === "Reserved")
      return "rgb(75, 192, 192)"
    else if (status === "Charging")
      return "rgb(0, 128, 1)"
    return "rgb(218, 165, 32)"
  }

  let iconComponent;

  if (notifications && notifications.length > 0) {
    iconComponent = (
      <Icon
        // className={`${classes.icon} ${classes.newNotification}`}
        sx={{ color: darkTheme ? '#f2e34c' : '#000' }}
      >
        notifications
      </Icon>
    );
  } else {
    iconComponent = (
      <Icon

        sx={{ color: darkTheme ? '#f2e34c' : '#000' }}
      >
        notifications
      </Icon>
    );
  }

  return (
    <Fragment >
      <IconButton onClick={handleDrawerToggle}>
        <Badge color="error" badgeContent={notifications?.length}>
          {iconComponent}
        </Badge>
      </IconButton>

      <ThemeProvider theme={settings.themes[settings.activeTheme]}>
        <Drawer
          container={container}
          variant="temporary"
          anchor={'right'}
          open={panelOpen}
          onClose={handleDrawerToggle}
        // ModalProps={{
        //   //keepMounted: true,
        // }}
        >
          <Box sx={{ width: sideNavWidth, backgroundColor: darkTheme ? '#000000' : '#f9f9f9' }}>
            <Notification>
              <Icon color="primary">notifications</Icon>

              <Grid width={"50%"}>
                <h5 style={{ color: darkTheme ? '#ffffff' : '#000000' }}>Notifications</h5>
              </Grid>

              {!!notifications?.length && (
                <Box sx={{ color: secondary }}>
                  <Button onClick={clearNotifications}>Clear Notifications</Button>
                </Box>
              )}

            </Notification>



            {notifications?.map((notification, index) => (
              <NotificationCard key={notification.userNotificationId} sx={{ backgroundColor: darkTheme ? '#000000' : (index % 2 !== 0 ? '#ffffff' : '#f9f9f9') }}
              // style={{ backgroundColor: ${index % 2 !== 0 ? ('#eeeeee') : ('#f9f9f9')}}}
              >
                <DeleteButton
                  size="small"
                  className="deleteButton"
                  onClick={() => deleteNotification(notification.userNotificationId)}
                >
                  <Icon className="icon">clear</Icon>
                </DeleteButton>

                <Card sx={{ mx: 2, mb: 3, backgroundColor: darkTheme ? '#333333' : '#ffffff' }} elevation={3}>
                  <CardLeftContent style={{ margin: 0, padding: 0 }}>
                    <Box display="flex" style={{ margin: 0, paddingTop: 5 }}>
                      <InfoIcon className="icon" style={{ marginTop: '2px', color: getStatusColor(notification.status) }} />
                      {/* {notification.status} */}
                      {/* </Icon> */}
                      <Heading style={{ padding: '1px', color: darkTheme ? '#ffffff' : '#000000' }}>{notification.status} ({notification.chargeBoxId + "-" + notification.connectorId})</Heading>&nbsp;:&nbsp;
                      <Paragraph sx={{ padding: '1px', color: darkTheme ? '#FFFFFF' : '#000000' }}>{notification.note}</Paragraph>

                    </Box>

                  </CardLeftContent>

                  {
                    notification.errorCode && notification.errorCode !== "NoError" ?
                      <CardLeftContent style={{ margin: 0, padding: 0 }}>
                        <Box display="flex">

                          <Heading style={{ paddingLeft: '20px', color: darkTheme ? '#ffffff' : '#000000' }}>Error Code : </Heading>&nbsp;
                          <Paragraph sx={{ padding: '1px', color: darkTheme ? '#FFFFFF' : '#000000' }}>{notification.errorCode}</Paragraph>

                        </Box>
                      </CardLeftContent> : ""

                  }



                  {
                    notification.vendorErrorCode ?
                      <CardLeftContent style={{ margin: 0, padding: 0 }}>
                        <Box display="flex">

                          <Heading style={{ paddingLeft: '20px', color: darkTheme ? '#ffffff' : '#000000' }}>Vendor Error Code : </Heading>&nbsp;
                          <Paragraph sx={{ padding: '1px', color: darkTheme ? '#FFFFFF' : '#000000' }}>{notification.vendorErrorCode}</Paragraph>

                        </Box>
                      </CardLeftContent> : ""
                  }

                  {
                    notification.alarmCause ?
                      <CardLeftContent style={{ margin: 0, padding: 0 }}>
                        <Box display="flex">

                          <Heading style={{ paddingLeft: '20px', color: darkTheme ? '#ffffff' : '#000000' }}>Cause : </Heading>&nbsp;
                          <Paragraph sx={{ padding: '1px', color: darkTheme ? '#FFFFFF' : '#000000' }}>{notification.alarmCause}</Paragraph>

                        </Box>
                      </CardLeftContent> : ""
                  }




                  <Grid style={{ paddingLeft: "15px", fontSize: "12px" }} sx={{ color: darkTheme ? '#FFFFFF' : '#000000' }}>
                    {new Date(notification.datetime).toLocaleString()}
                  </Grid>
                  {/* <Box sx={{ px: 2, pt: 1, pb: 2 }}> */}
                  {/* <Paragraph sx={{ m: 0, color: darkTheme ? '#FFFFFF' : '#000000' }}>{notification.note}</Paragraph> */}
                  {/* <Small sx={{ color: secondary }}>{notification.status}</Small> */}
                  {/* </Box> */}

                </Card>
              </NotificationCard>
            ))}

          </Box>
        </Drawer>
      </ThemeProvider>
    </Fragment >
  );
};
export default NotificationBar;